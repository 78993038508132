// src/core/pages/PanelPage/PanelPage.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../../components/Header/Header';
import SideMenu from '../../components/SideMenu/SideMenu';
import RolesComponent from '../../components/Admin/RolesComponent';
import AdminRegisterButton from '../../components/Admin/AdminRegisterButton';

const PanelPage = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          <Typography variant="h4" gutterBottom>Panel de Control</Typography>
          <RolesComponent />
          <AdminRegisterButton />
        </Box>
      </Box>
    </Box>
  );
};

export default PanelPage;