// src/core/components/SideMenu/SideMenu.js

import React, { useState, useEffect } from 'react';
import { Drawer, List, ListItem, ListItemIcon, ListItemText, Divider, IconButton, Box } from '@mui/material';
import { useLocation, useNavigate } from 'react-router-dom';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import { styled } from '@mui/material/styles';
import { useAdminAuth } from '../../context/AdminAuthContext';
import ProfileModal from '../../components/Admin/ProfileModal';
import { menuItems } from '../../config/menuItems';

const LogoContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  padding: theme.spacing(2),
  paddingBottom: '8px',
  transition: 'all 0.3s ease-in-out',
  borderBottom: '2px solid #f5f5f5',
}));

const StyledListItem = styled(({ isActive, ...props }) => <ListItem {...props} />)(
  ({ theme, isActive }) => ({
    backgroundColor: isActive ? '#d60f16' : 'inherit',
    '&:hover': {
      backgroundColor: '#b60d13',
      '& .MuiListItemIcon-root, & .MuiListItemText-root': {
        color: '#ffffff',
      },
    },
    '& .MuiListItemIcon-root, & .MuiListItemText-root': {
      color: isActive ? '#ffffff' : 'rgba(0, 0, 0, 0.54)',
      transition: 'color 0.3s',
    },
    height: '48px',
    padding: 0,
  })
);

const StyledListItemIcon = styled(ListItemIcon)({
  minWidth: '56px',
  display: 'flex',
  justifyContent: 'center',
});

const CollapseButton = styled(IconButton)(({ theme }) => ({
  position: 'absolute',
  bottom: theme.spacing(1),
  left: '50%',
  transform: 'translateX(-50%)',
  transition: 'all 0.3s ease-in-out',
}));

const SideMenu = () => {
  const [collapsed, setCollapsed] = useState(() => {
    const savedState = localStorage.getItem('sideMenuCollapsed');
    return savedState !== null ? JSON.parse(savedState) : true;
  });
  const location = useLocation();
  const navigate = useNavigate();
  const { adminUser } = useAdminAuth();
  const [profileModalOpen, setProfileModalOpen] = useState(false);

  useEffect(() => {
    localStorage.setItem('sideMenuCollapsed', JSON.stringify(collapsed));
  }, [collapsed]);

  const toggleCollapse = () => {
    setCollapsed(!collapsed);
  };

  const handleMenuItemClick = (path) => {
    navigate(path);
  };

  const handleProfileClick = () => {
    setProfileModalOpen(true);
  };

  return (
    <>
      <Drawer
        variant="permanent"
        sx={{
          width: collapsed ? 60 : 240,
          flexShrink: 0,
          '& .MuiDrawer-paper': {
            width: collapsed ? 60 : 240,
            boxSizing: 'border-box',
            borderRight: '2px solid #f5f5f5',
            overflowX: 'hidden',
            transition: 'width 0.3s',
          },
        }}
      >
        <LogoContainer>
          {collapsed ? (
            <img src="/img/logo2.png" alt="Short Logo" height="40" />
          ) : (
            <img src="/img/logo.png" alt="Full Logo" height="40" style={{ marginLeft: '16px' }} />
          )}
        </LogoContainer>
        <List sx={{ flexGrow: 1, pt: 0 }}>
          {menuItems.map((item) => (
            <StyledListItem
              key={item.text}
              button
              onClick={() => handleMenuItemClick(item.path)}
              isActive={location.pathname === item.path}
            >
              <StyledListItemIcon>
                <item.icon />
              </StyledListItemIcon>
              {!collapsed && <ListItemText primary={item.text} />}
            </StyledListItem>
          ))}
          <StyledListItem
            button
            onClick={handleProfileClick}
            isActive={false}
          >
            <StyledListItemIcon><AccountCircleIcon /></StyledListItemIcon>
            {!collapsed && <ListItemText primary="Perfil" />}
          </StyledListItem>
        </List>
        <Divider />
        <Box sx={{ height: 48, position: 'relative' }}>
          <CollapseButton onClick={toggleCollapse}>
            {collapsed ? <ChevronRightIcon /> : <ChevronLeftIcon />}
          </CollapseButton>
        </Box>
      </Drawer>
      <ProfileModal 
        open={profileModalOpen} 
        handleClose={() => setProfileModalOpen(false)} 
      />
    </>
  );
};

export default SideMenu;