// src/core/components/Admin/AdminRegisterModal.js

import React, { useState } from 'react';
import { Modal, Box, TextField, Button, Typography, Select, MenuItem, FormControl, InputLabel, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { adminRegister } from '../../utils/api';
import { departments, roles } from '../../../modules/services/config';

const AdminRegisterModal = ({ open, handleClose }) => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    password: '',
    department: '',
    role: '',
  });
  const [error, setError] = useState('');
  const [successDialogOpen, setSuccessDialogOpen] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await adminRegister(formData);
      setSuccessDialogOpen(true);
      // Reset form data
      setFormData({
        name: '',
        email: '',
        password: '',
        department: '',
        role: '',
      });
    } catch (error) {
      if (error.response && error.response.data.error === 'El correo electrónico ya está en uso') {
        setError('El correo electrónico ya está en uso. Por favor, use otro correo.');
      } else {
        setError('Error al registrar usuario. Por favor, inténtelo de nuevo.');
      }
    }
  };

  const handleSuccessClose = () => {
    setSuccessDialogOpen(false);
    handleClose();
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Box sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: 400,
          bgcolor: 'background.paper',
          boxShadow: 24,
          p: 4,
        }}>
          <Typography variant="h6" component="h2" gutterBottom>
            Registrar Usuario Interno
          </Typography>
          <form onSubmit={handleSubmit}>
            <TextField
              fullWidth
              label="Nombre"
              name="name"
              value={formData.name}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Correo"
              name="email"
              type="email"
              value={formData.email}
              onChange={handleChange}
              margin="normal"
              required
            />
            <TextField
              fullWidth
              label="Contraseña"
              name="password"
              type="password"
              value={formData.password}
              onChange={handleChange}
              margin="normal"
              required
            />
            <FormControl fullWidth margin="normal">
              <InputLabel>Departamento</InputLabel>
              <Select
                name="department"
                value={formData.department}
                onChange={handleChange}
                required
              >
                {departments.map((dept) => (
                  <MenuItem key={dept} value={dept}>{dept}</MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth margin="normal">
              <InputLabel>Rol</InputLabel>
              <Select
                name="role"
                value={formData.role}
                onChange={handleChange}
                required
              >
                {roles.map((role) => (
                  <MenuItem key={role} value={role}>{role}</MenuItem>
                ))}
              </Select>
            </FormControl>
            {error && <Typography color="error">{error}</Typography>}
            <Button type="submit" variant="contained" color="primary" fullWidth sx={{ mt: 2 }}>
              Registrar
            </Button>
          </form>
        </Box>
      </Modal>

      <Dialog open={successDialogOpen} onClose={handleSuccessClose}>
        <DialogTitle>Registro Exitoso</DialogTitle>
        <DialogContent>
          <Typography>
            El usuario interno ha sido registrado correctamente.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleSuccessClose} color="primary">
            Aceptar
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
};

export default AdminRegisterModal;