// src/core/components/AdminRegisterButton/AdminRegisterButton.js

import React, { useState } from 'react';
import { Button } from '@mui/material';
import AdminRegisterModal from '../Admin/AdminRegisterModal';

const AdminRegisterButton = () => {
  const [registerModalOpen, setRegisterModalOpen] = useState(false);

  const handleOpenRegisterModal = () => {
    setRegisterModalOpen(true);
  };

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={handleOpenRegisterModal}
      >
        Registrar Usuario
      </Button>
      <AdminRegisterModal 
        open={registerModalOpen} 
        handleClose={() => setRegisterModalOpen(false)} 
      />
    </>
  );
};

export default AdminRegisterButton;