// src/modules/dashboard/pages/HomePage.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../../../core/components/Header/Header';
import SideMenu from '../../../core/components/SideMenu/SideMenu';
import DashboardComponent from '../components/DashboardComponent';

const HomePage = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto' }}>
          <Typography variant="h2" sx={{ mb: 4 }}>Bienvenido</Typography>
          <DashboardComponent />
        </Box>
      </Box>
    </Box>
  );
};

export default HomePage;