// src/core/components/SessionTimeoutModal/SessionTimeoutModal.js

import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography } from '@mui/material';
import { useAdminAuth } from '../../context/AdminAuthContext';
import { debounce } from 'lodash'; // Make sure to install lodash if not already installed

const SessionTimeoutModal = () => {
  const [open, setOpen] = useState(false);
  const [countdown, setCountdown] = useState(30);
  const { logout, resetSession } = useAdminAuth();
  const timeoutRef = useRef(null);
  const intervalRef = useRef(null);

  const handleLogout = useCallback(() => {
    clearInterval(intervalRef.current);
    logout();
    setOpen(false);
  }, [logout]);

  const resetTimeout = useCallback(() => {
    clearTimeout(timeoutRef.current);
    clearInterval(intervalRef.current);
    setOpen(false);
    setCountdown(30);
    resetSession();
    startTimer();
  }, [resetSession]);

  const startCountdown = useCallback(() => {
    setCountdown(30);
    intervalRef.current = setInterval(() => {
      setCountdown((prevCountdown) => {
        if (prevCountdown <= 1) {
          clearInterval(intervalRef.current);
          handleLogout();
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);
  }, [handleLogout]);

  const startTimer = useCallback(() => {
    timeoutRef.current = setTimeout(() => {
      setOpen(true);
    }, 60 * 60 * 1000); // 1 hour
  }, []);

  const resetTimer = useCallback(() => {
    if (!open) {
      clearTimeout(timeoutRef.current);
      clearInterval(intervalRef.current);
      setCountdown(30);
      startTimer();
    }
  }, [open, startTimer]);

  // Debounced version of resetTimer
  const debouncedResetTimer = useCallback(
    debounce(resetTimer, 300), // 300ms delay
    [resetTimer]
  );

  useEffect(() => {
    startTimer();

    const handleActivity = () => {
      if (!open) {
        debouncedResetTimer();
      }
    };

    window.addEventListener('mousemove', handleActivity);
    window.addEventListener('keypress', handleActivity);

    return () => {
      clearTimeout(timeoutRef.current);
      clearInterval(intervalRef.current);
      window.removeEventListener('mousemove', handleActivity);
      window.removeEventListener('keypress', handleActivity);
      debouncedResetTimer.cancel();
    };
  }, [open, debouncedResetTimer, startTimer]);

  useEffect(() => {
    if (open) {
      startCountdown();
    } else {
      clearInterval(intervalRef.current);
    }
  }, [open, startCountdown]);

  return (
    <Dialog open={open} onClose={() => {}} disableEscapeKeyDown>
      <DialogTitle>Sesión a punto de expirar</DialogTitle>
      <DialogContent>
        <Typography>
          Su sesión expirará en {countdown} segundos debido a inactividad. ¿Desea continuar con la sesión?
        </Typography>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleLogout} variant="contained"color="primary">
          Cerrar sesión
        </Button>
        <Button onClick={resetTimeout} color="primary" autoFocus>
          Continuar sesión
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default SessionTimeoutModal;