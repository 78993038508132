// src/core/components/Admin/Login.js

import React, { useState, useEffect } from 'react';
import { Box, Button, Typography, TextField } from '@mui/material';
import { useNavigate, useLocation } from 'react-router-dom';
import { useAdminAuth } from '../../context/AdminAuthContext';

const Login = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const { login, adminUser, loading } = useAdminAuth();

  useEffect(() => {
    if (adminUser && location.pathname === '/login') {
      navigate('/');
    }
  }, [adminUser, navigate, location.pathname]);

  const handleLogin = async (e) => {
    e.preventDefault();
    setError('');
    try {
      await login(email, password);
    } catch (error) {
      console.error('Login error:', error);
      setError('Invalid email or password');
    }
  };

  if (loading) {
    return <Typography>Cargando...</Typography>;
  }

  if (adminUser) {
    return null;
  }

  return (
    <Box sx={{ 
      maxWidth: 400, 
      width: '100%', 
      backgroundColor: 'white', 
      padding: 4, 
      borderRadius: 2,
      boxShadow: '0px 0px 10px rgba(0,0,0,0.1)'
    }}>
      <Typography variant="h4" gutterBottom align="center">
        Acceso
      </Typography>
      <form onSubmit={handleLogin}>
        <TextField
          fullWidth
          label="Correo"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        <TextField
          fullWidth
          label="Contraseña"
          type="password"
          value={password}
          onChange={(e) => setPassword(e.target.value)}
          margin="normal"
          variant="outlined"
        />
        {error && (
          <Typography color="error" align="center" sx={{ mt: 2 }}>
            {error}
          </Typography>
        )}
        <Button 
          type="submit" 
          variant="contained" 
          color="primary" 
          fullWidth 
          sx={{ mt: 3, mb: 2 }}
        >
          Entrar
        </Button>
      </form>
    </Box>
  );
};

export default Login;