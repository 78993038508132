  // src/modules/PlaceholderTable/pages/PlaceholderPage.js

  import React, { useState, useEffect, useMemo } from 'react';
  import {
    Box,
    FormControl,
    InputLabel,
    MenuItem,
    Select,
    Snackbar,
    Alert,
    Typography,
  } from '@mui/material';
  import { DatePicker } from '@mui/x-date-pickers/DatePicker';
  import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
  import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
  import { format, parse, isAfter, isBefore, isEqual } from 'date-fns';
  import Header from '../../../core/components/Header/Header';
  import SideMenu from '../../../core/components/SideMenu/SideMenu';
  import PlaceholderTable from '../components/PlaceholderTable';
  import PlaceholderDetailsModal from '../components/PlaceholderDetailsModal';
  import AccessDenied from '../../../core/components/AccessDenied/AccessDenied';
  import { getFormData } from '../../../core/utils/api';
  import { useAdminAuth } from '../../../core/context/AdminAuthContext';

  const PlaceholderPage = () => {
    const [searchTerm, setSearchTerm] = useState('');
    const [status, setStatus] = useState('');
    const [startDate, setStartDate] = useState(null);
    const [endDate, setEndDate] = useState(null);
    const [quickDateFilter, setQuickDateFilter] = useState('');
    const [open, setOpen] = useState(false);
    const [selectedItem, setSelectedItem] = useState(null);
    const [sortOrder, setSortOrder] = useState('desc');
    const { checkPermission } = useAdminAuth();
    const canViewTable = checkPermission(3); // 3 is the ID for "Ver página de tabla"
    const [snackbar, setSnackbar] = useState({
      open: false,
      message: '',
      severity: 'success',
    });
    const [formData, setFormData] = useState([]);

    useEffect(() => {
      if (canViewTable) {
        fetchFormData();
      }
    }, [canViewTable]);

    const fetchFormData = async () => {
      try {
        const data = await getFormData();
        setFormData(data);
      } catch (error) {
        console.error('Error fetching form data:', error);
        setSnackbar({
          open: true,
          message: 'Error al cargar los datos',
          severity: 'error',
        });
      }
    };

    const handleSearchChange = (value) => {
      setSearchTerm(value);
    };

    const handleStatusChange = (event) => {
      setStatus(event.target.value);
    };

    const handleQuickDateFilterChange = (event) => {
      const value = event.target.value;
      setQuickDateFilter(value);
      
      const today = new Date();
      today.setHours(0, 0, 0, 0);

      switch (value) {
        case 'today':
          setStartDate(today);
          setEndDate(today);
          break;
        case 'yesterdayAndToday':
          const yesterday = new Date(today);
          yesterday.setDate(yesterday.getDate() - 1);
          setStartDate(yesterday);
          setEndDate(today);
          break;
        case 'thisWeek':
          const startOfWeek = new Date(today);
          startOfWeek.setDate(today.getDate() - today.getDay());
          setStartDate(startOfWeek);
          setEndDate(today);
          break;
        case 'thisMonth':
          const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
          setStartDate(startOfMonth);
          setEndDate(today);
          break;
        default:
          setStartDate(null);
          setEndDate(null);
      }
    };

    const handleOpen = (item) => {
      setSelectedItem(item);
      setOpen(true);
    };

    const handleClose = () => {
      setOpen(false);
      setSelectedItem(null);
    };

    const handlePrint = (id) => {
      console.log(`Printing item with ID: ${id}`);
      setSnackbar({
        open: true,
        message: `Impresión iniciada para el item ${id}`,
        severity: 'success',
      });
    };

    const handleUpdateSuccess = (updatedItem) => {
      const updatedFormData = formData.map(item => 
        item.id === updatedItem.id ? updatedItem : item
      );
      setFormData(updatedFormData);
      setSnackbar({
        open: true,
        message: 'Datos actualizados exitosamente',
        severity: 'success',
      });
    };

    const handleCloseSnackbar = (event, reason) => {
      if (reason === 'clickaway') {
        return;
      }
      setSnackbar({ ...snackbar, open: false });
    };

    const handleSortOrderChange = () => {
      setSortOrder(prevOrder => prevOrder === 'desc' ? 'asc' : 'desc');
    };

    const filteredData = useMemo(() => {
      return formData
        .filter(item => {
          const searchTermLower = searchTerm.toLowerCase();
          const matchesSearch = 
            (item.nombre?.toLowerCase().includes(searchTermLower) ?? false) ||
            (item.rncCedula?.toLowerCase().includes(searchTermLower) ?? false) ||
            (item.rncRelacionado?.toLowerCase().includes(searchTermLower) ?? false) ||
            (item.telefono?.includes(searchTerm) ?? false) ||
            (item.displayId?.toLowerCase().includes(searchTermLower) ?? false) ||
            (item.status?.toLowerCase().includes(searchTermLower) ?? false);
          const matchesStatus = status === '' || item.status === status;
          const itemDate = item.date ? parse(item.date, 'dd/MM/yyyy', new Date()) : null;
          const isAfterStartDate = !startDate || !itemDate || isAfter(itemDate, startDate) || isEqual(itemDate, startDate);
          const isBeforeEndDate = !endDate || !itemDate || isBefore(itemDate, endDate) || isEqual(itemDate, endDate);
    
          return matchesSearch && matchesStatus && isAfterStartDate && isBeforeEndDate;
        })
        .sort((a, b) => {
          const dateA = parse(a.date, 'dd/MM/yyyy', new Date());
          const dateB = parse(b.date, 'dd/MM/yyyy', new Date());
          
          return sortOrder === 'desc' 
            ? dateB.getTime() - dateA.getTime()
            : dateA.getTime() - dateB.getTime();
        });
    }, [formData, searchTerm, status, startDate, endDate, sortOrder]);

  if (!canViewTable) {
    return <AccessDenied />;
  }

    return (
      <Box sx={{ display: 'flex' }}>
        <SideMenu />
        <Box sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', overflow: 'hidden' }}>
          <Header search={searchTerm} onSearchChange={handleSearchChange} />
          <Box sx={{ p: 2, display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap', gap: 2 }}>
            <Box sx={{ display: 'flex', gap: 2 }}>
              <FormControl variant="outlined">
                <InputLabel id="status-label">Estado</InputLabel>
                <Select
                  labelId="status-label"
                  value={status}
                  onChange={handleStatusChange}
                  label="Estado"
                  size="small"
                >
                  <MenuItem value="">Todo</MenuItem>
                  <MenuItem value="Pendiente">Pendiente</MenuItem>
                  <MenuItem value="Recibido">Recibido</MenuItem>
                  <MenuItem value="Procesando">Procesando</MenuItem>
                  <MenuItem value="Aprobado">Aprobado</MenuItem>
                  <MenuItem value="Rechazado">Rechazado</MenuItem>
                </Select>
              </FormControl>
            </Box>
            <Box sx={{ display: 'flex', gap: 2, alignItems: 'center' }}>
              <FormControl variant="outlined">
                <InputLabel id="quick-filter-label">Filtro rápido</InputLabel>
                <Select
                  labelId="quick-filter-label"
                  value={quickDateFilter}
                  onChange={handleQuickDateFilterChange}
                  label="Filtro rápido"
                  size="small"
                >
                  <MenuItem value="">Ninguno</MenuItem>
                  <MenuItem value="today">Hoy</MenuItem>
                  <MenuItem value="yesterdayAndToday">Ayer y Hoy</MenuItem>
                  <MenuItem value="thisWeek">Esta Semana</MenuItem>
                  <MenuItem value="thisMonth">Este Mes</MenuItem>
                </Select>
              </FormControl>
              <LocalizationProvider dateAdapter={AdapterDateFns}>
                <DatePicker
                  label="Fecha Inicio"
                  value={startDate}
                  onChange={(newValue) => setStartDate(newValue)}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      size: "small",
                    }
                  }}
                />
                <DatePicker
                  label="Fecha Fin"
                  value={endDate}
                  onChange={(newValue) => setEndDate(newValue)}
                  format="dd/MM/yyyy"
                  slotProps={{
                    textField: {
                      size: "small",
                    }
                  }}
                />
              </LocalizationProvider>
            </Box>
          </Box>
          <Box sx={{ flexGrow: 1, overflow: 'auto', p: 2 }}>
            {canViewTable ? (
              <PlaceholderTable 
                data={filteredData} 
                onOpenDetails={handleOpen}
                sortOrder={sortOrder}
                onSortOrderChange={handleSortOrderChange}
              />
            ) : (
              <Typography>No tienes permiso para ver esta tabla.</Typography>
            )}
          </Box>
        </Box>

        <PlaceholderDetailsModal
          open={open}
          handleClose={handleClose}
          selectedItem={selectedItem}
          onUpdateSuccess={handleUpdateSuccess}
        />

        <Snackbar 
          open={snackbar.open} 
          autoHideDuration={6000} 
          onClose={handleCloseSnackbar}
          anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
        >
          <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
            {snackbar.message}
          </Alert>
        </Snackbar>
      </Box>
    );
  };

  export default PlaceholderPage;