// src/core/pages/LoginPage/LoginPage.js

import React from 'react';
import { Box } from '@mui/material';
import Login from '../../components/Admin/Login';

const LoginPage = () => {
  return (
    <Box sx={{ 
      display: 'flex', 
      flexDirection: 'column', 
      alignItems: 'center', 
      justifyContent: 'center', 
      height: '100vh',
      backgroundColor: '#f5f5f5'
    }}>
      <Login />
    </Box>
  );
};

export default LoginPage;