// src/core/config/menuItems.js

import HomeIcon from '@mui/icons-material/Home';
import PeopleIcon from '@mui/icons-material/People';
import AssignmentIcon from '@mui/icons-material/Assignment';
import TableChart from '@mui/icons-material/TableChart';

export const menuItems = [
  { text: 'Inicio', icon: HomeIcon, path: '/' },
  { text: 'Formulario', icon: AssignmentIcon, path: '/form' },
  { text: 'Tabla', icon: TableChart, path: '/placeholder' },
  { text: 'Usuarios', icon: PeopleIcon, path: '/users' },
];