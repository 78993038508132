// src/modules/form/pages/FormPage.js

import React, { useState } from 'react';
import { Box, Snackbar, Alert } from '@mui/material';
import Header from '../../../core/components/Header/Header';
import SideMenu from '../../../core/components/SideMenu/SideMenu';
import FormComponent from '../components/FormComponent';
import AccessDenied from '../../../core/components/AccessDenied/AccessDenied';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';

const FormPage = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const { checkPermission } = useAdminAuth();
  const canViewForm = checkPermission(2); // 2 is the ID for "Ver página de formulario"
  const [snackbar, setSnackbar] = useState({
    open: false,
    message: '',
    severity: 'success',
  });

  const handleSubmitSuccess = (displayId) => {
    setSnackbar({
      open: true,
      message: `Datos guardados exitosamente. ID: ${displayId}`,
      severity: 'success',
    });
  };

  const handleSubmitError = () => {
    setSnackbar({
      open: true,
      message: 'Error al guardar los datos',
      severity: 'error',
    });
  };

  const handleCloseSnackbar = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
    setSnackbar({ ...snackbar, open: false });
  };

  const handleSearchChange = (value) => {
    setSearchTerm(value);
  };

  if (!canViewForm) {
    return <AccessDenied />;
  }

  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header search={searchTerm} onSearchChange={handleSearchChange}/>
        <Box sx={{ flexGrow: 1, p: 3, overflow: 'auto', display: 'flex', justifyContent: 'center' }}>
          <FormComponent
            onSubmitSuccess={handleSubmitSuccess}
            onSubmitError={handleSubmitError}
          />
        </Box>
      </Box>
      <Snackbar 
        open={snackbar.open} 
        autoHideDuration={6000} 
        onClose={handleCloseSnackbar}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert onClose={handleCloseSnackbar} severity={snackbar.severity} sx={{ width: '100%' }}>
          {snackbar.message}
        </Alert>
      </Snackbar>
    </Box>
  );
};

export default FormPage;