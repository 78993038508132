import React, { useState, useEffect, useCallback } from 'react';
import { debounce } from 'lodash';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
  Box,
  IconButton,
  TextField,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Grid,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import ClearIcon from '@mui/icons-material/Clear';
import HistoryIcon from '@mui/icons-material/History';
import { updateFormData, addOrderHistoryEvents } from '../../../core/utils/api';
import { useAdminAuth } from '../../../core/context/AdminAuthContext';
import ConfirmDialog from '../../../core/components/ConfirmDialog/ConfirmDialog';

const PlaceholderDetailsModal = ({ open, handleClose, selectedItem, onUpdateSuccess }) => {
  const [editableItem, setEditableItem] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [pendingHistoryEvents, setPendingHistoryEvents] = useState([]);
  const [confirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [confirmDialogAction, setConfirmDialogAction] = useState(null);
  const [hasUnsavedChanges, setHasUnsavedChanges] = useState(false);
  const { checkPermission, adminUser } = useAdminAuth();
  const canEditGeneral = checkPermission(4);
  const canEditDetails = checkPermission(5);
  const [newNota, setNewNota] = useState('');
  const [savedNotas, setSavedNotas] = useState([]);

  const handleAddNota = () => {
    if (newNota) {
      setEditableItem(prevItem => ({
        ...prevItem,
        notas: [...(prevItem.notas || []), newNota]
      }));
      addPendingHistoryEvent(`Nueva nota agregada: ${newNota}`);
      setNewNota('');
    }
  };

  useEffect(() => {
    if (selectedItem) {
      setEditableItem({
        ...selectedItem,
        historial: selectedItem.historial || [],
      });
      setSavedNotas(selectedItem.notas || []);
      setIsEditing(false);
      setPendingHistoryEvents([]);
      setHasUnsavedChanges(false);
    }
  }, [selectedItem]);

  const addPendingHistoryEvent = (event) => {
    setPendingHistoryEvents(prev => {
      const newEvent = {
        date: new Date().toISOString(),
        event: event,
        userName: adminUser ? adminUser.name : 'Sistema'
      };
      
      const existingEventIndex = prev.findIndex(e => e.event.startsWith(event.split(' cambiado')[0]));
      if (existingEventIndex !== -1 && event.includes('cambiado')) {
        const updatedEvents = [...prev];
        updatedEvents[existingEventIndex] = newEvent;
        return updatedEvents;
      } else {
        return [...prev, newEvent];
      }
    });
    setHasUnsavedChanges(true);
  };

  const debouncedAddPendingHistoryEvent = useCallback(
    debounce(addPendingHistoryEvent, 500),
    [adminUser]
  );

  const handleInputChange = (field, value) => {
    if (canEditGeneral || canEditDetails) {
      setEditableItem(prevItem => ({
        ...prevItem,
        [field]: value
      }));
      debouncedAddPendingHistoryEvent(`${field} cambiado a ${value}`);
    }
  };

  const toggleEditMode = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = async () => {
    setConfirmDialogOpen(true);
    setConfirmDialogAction('save');
  };

  const handleConfirmSave = async () => {
    setConfirmDialogOpen(false);
    try {
      const updatedItem = {
        ...editableItem,
        userName: adminUser ? adminUser.name : 'Sistema'
      };

      await updateFormData(updatedItem.id, updatedItem);

      if (pendingHistoryEvents.length > 0) {
        await addOrderHistoryEvents(updatedItem.id, pendingHistoryEvents);
        
        setEditableItem(prevItem => ({
          ...prevItem,
          historial: [...(prevItem.historial || []), ...pendingHistoryEvents]
        }));

        setPendingHistoryEvents([]);
      }

      setSavedNotas(updatedItem.notas || []);
      onUpdateSuccess(updatedItem);
      setIsEditing(false);
      setHasUnsavedChanges(false);
      handleClose();
    } catch (error) {
      console.error('Error updating form data:', error);
      // Handle error (e.g., show error message to user)
    }
  };

  const handleCloseModal = () => {
    if (hasUnsavedChanges) {
      setConfirmDialogOpen(true);
      setConfirmDialogAction('close');
    } else {
      handleClose();
    }
  };

  const handleConfirmClose = () => {
    setConfirmDialogOpen(false);
    setHasUnsavedChanges(false);
    handleClose();
  };

  if (!editableItem) return null;

  return (
    <>
      <Dialog
        open={open}
        onClose={handleCloseModal}
        fullWidth
        maxWidth="md"
        PaperProps={{
          sx: {
            width: '100%',
            maxWidth: '800px',
            height: '90vh',
            display: 'flex',
            flexDirection: 'column',
          }
        }}
      >
        <DialogTitle>
          <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
            <Typography variant="h6" fontWeight="bold">
              Detalles de la Solicitud de Crédito
            </Typography>
            <IconButton onClick={handleCloseModal}>
              <ClearIcon />
            </IconButton>
          </Box>
          <Typography variant="body2" color="text.secondary">
            UUID: {editableItem.id}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            ID: {editableItem.displayId}
          </Typography>
          <Typography variant="body2" color="text.secondary">
            Fecha: {editableItem.date}
          </Typography>
          </DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h6">Información General</Typography>
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Nombre"
                value={editableItem.nombre || ''}
                onChange={(e) => handleInputChange('nombre', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="RNC / Cédula"
                value={editableItem.rncCedula || ''}
                onChange={(e) => handleInputChange('rncCedula', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="RNC Relacionado"
                value={editableItem.rncRelacionado || ''}
                onChange={(e) => handleInputChange('rncRelacionado', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Dirección"
                value={editableItem.direccion || ''}
                onChange={(e) => handleInputChange('direccion', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Sector"
                value={editableItem.sector || ''}
                onChange={(e) => handleInputChange('sector', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Provincia"
                value={editableItem.provincia || ''}
                onChange={(e) => handleInputChange('provincia', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Municipio"
                value={editableItem.municipio || ''}
                onChange={(e) => handleInputChange('municipio', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Teléfono"
                value={editableItem.telefono || ''}
                onChange={(e) => handleInputChange('telefono', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Móvil"
                value={editableItem.movil || ''}
                onChange={(e) => handleInputChange('movil', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Correo"
                value={editableItem.correo || ''}
                onChange={(e) => handleInputChange('correo', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Fecha Constitución del Negocio"
                value={editableItem.fechaConstitucion || ''}
                onChange={(e) => handleInputChange('fechaConstitucion', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <TextField
                fullWidth
                label="Actividad Comercial"
                value={editableItem.actividadComercial || ''}
                onChange={(e) => handleInputChange('actividadComercial', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel>Forma de Pago</InputLabel>
                <Select
                  value={editableItem.formaPago || ''}
                  onChange={(e) => handleInputChange('formaPago', e.target.value)}
                >
                  <MenuItem value="Cheque">Cheque</MenuItem>
                  <MenuItem value="Efectivo">Efectivo</MenuItem>
                  <MenuItem value="Transferencia">Transferencia</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel>Día de Pago</InputLabel>
                <Select
                  value={editableItem.diaPago || ''}
                  onChange={(e) => handleInputChange('diaPago', e.target.value)}
                >
                  {[...Array(31)].map((_, i) => (
                    <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel>Inmueble del Negocio</InputLabel>
                <Select
                  value={editableItem.inmuebleNegocio || ''}
                  onChange={(e) => handleInputChange('inmuebleNegocio', e.target.value)}
                >
                  <MenuItem value="Propio">Propio</MenuItem>
                  <MenuItem value="Alquilado">Alquilado</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            <Grid item xs={12} md={6}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel>Seguro Contra Incendio</InputLabel>
                <Select
                  value={editableItem.seguroContraIncendio || ''}
                  onChange={(e) => handleInputChange('seguroContraIncendio', e.target.value)}
                >
                  <MenuItem value="Si">Sí</MenuItem>
                  <MenuItem value="No">No</MenuItem>
                </Select>
              </FormControl>
            </Grid>
            {editableItem.seguroContraIncendio === 'Si' && (
              <Grid item xs={12}>
                <TextField
                  fullWidth
                  label="Compañía Aseguradora"
                  value={editableItem.companiaAseguradora || ''}
                  onChange={(e) => handleInputChange('companiaAseguradora', e.target.value)}
                  disabled={!isEditing}
                />
              </Grid>
            )}
            <Grid item xs={12}>
              <Typography variant="h6">Información de Contacto</Typography>
            </Grid>
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Cargo"
                value={editableItem.cargoContacto || ''}
                onChange={(e) => handleInputChange('cargoContacto', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Administrador</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nombre y Apellido"
                value={editableItem.administradorNombre || ''}
                onChange={(e) => handleInputChange('administradorNombre', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tel. Móvil"
                value={editableItem.administradorTelefono || ''}
                onChange={(e) => handleInputChange('administradorTelefono', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Correo"
                value={editableItem.administradorCorreo || ''}
                onChange={(e) => handleInputChange('administradorCorreo', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Enc. de Compras</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nombre y Apellido"
                value={editableItem.encComprasNombre || ''}
                onChange={(e) => handleInputChange('encComprasNombre', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tel. Móvil"
                value={editableItem.encComprasTelefono || ''}
                onChange={(e) => handleInputChange('encComprasTelefono', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Correo"
                value={editableItem.encComprasCorreo || ''}
                onChange={(e) => handleInputChange('encComprasCorreo', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12}>
              <Typography variant="subtitle1">Enc. de CxP</Typography>
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Nombre y Apellido"
                value={editableItem.encCxPNombre || ''}
                onChange={(e) => handleInputChange('encCxPNombre', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Tel. Móvil"
                value={editableItem.encCxPTelefono || ''}
                onChange={(e) => handleInputChange('encCxPTelefono', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>
            <Grid item xs={12} md={4}>
              <TextField
                fullWidth
                label="Correo"
                value={editableItem.encCxPCorreo || ''}
                onChange={(e) => handleInputChange('encCxPCorreo', e.target.value)}
                disabled={!isEditing}
              />
            </Grid>

             {/* Estado */}
             <Grid item xs={12}>
              <Typography variant="h6">Estado</Typography>
            </Grid>
            
            {/* Estado */}
            <Grid item xs={12}>
              <FormControl fullWidth disabled={!isEditing}>
                <InputLabel>Estado</InputLabel>
                <Select
                  value={editableItem.status || ''}
                  onChange={(e) => handleInputChange('status', e.target.value)}
                >
                  <MenuItem value="Pendiente">Pendiente</MenuItem>
                  <MenuItem value="Recibido">Recibido</MenuItem>
                  <MenuItem value="Procesando">Procesando</MenuItem>
                  <MenuItem value="Aprobado">Aprobado</MenuItem>
                  <MenuItem value="Rechazado">Rechazado</MenuItem>
                </Select>
              </FormControl>
            </Grid>

            {/* Notas */}
            <Grid item xs={12}>
              <Typography variant="h6">Notas</Typography>
            </Grid>
            {savedNotas.map((nota, index) => (
              <Grid item xs={12} key={index}>
                <TextField
                  fullWidth
                  label={`Nota ${index + 1}`}
                  value={nota}
                  disabled={true}
                />
              </Grid>
            ))}
            {isEditing && editableItem.notas && editableItem.notas.slice(savedNotas.length).map((nota, index) => (
              <Grid item xs={12} key={index + savedNotas.length}>
                <TextField
                  fullWidth
                  label={`Nota ${index + savedNotas.length + 1}`}
                  value={nota}
                  onChange={(e) => {
                    const updatedNotas = [...editableItem.notas];
                    updatedNotas[index + savedNotas.length] = e.target.value;
                    handleInputChange('notas', updatedNotas);
                  }}
                />
              </Grid>
            ))}
            {isEditing && (
              <>
                <Grid item xs={12}>
                  <TextField
                    fullWidth
                    label="Nueva Nota"
                    value={newNota}
                    onChange={(e) => setNewNota(e.target.value)}
                  />
                </Grid>
                <Grid item xs={12}>
                  <Button
                    startIcon={<AddIcon />}
                    onClick={handleAddNota}
                    disabled={!newNota}
                  >
                    Agregar Nueva Nota
                  </Button>
                </Grid>
              </>
            )}

            {/* Historial */}
            <Grid item xs={12}>
              <Typography variant="h6">Historial</Typography>
            </Grid>
            <Grid item xs={12}>
              <List>
                {[...(editableItem?.historial || []), ...pendingHistoryEvents].map((entry, index) => (
                  <ListItem key={index}>
                    <ListItemIcon>
                      <HistoryIcon />
                    </ListItemIcon>
                    <ListItemText
                      primary={entry.event}
                      secondary={`${new Date(entry.date).toLocaleString()} - ${entry.userName}`}
                    />
                  </ListItem>
                ))}
              </List>
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          {!isEditing ? (
            <Button
              variant="contained"
              onClick={toggleEditMode}
              disabled={!canEditGeneral && !canEditDetails}
            >
              Editar
            </Button>
          ) : (
            <>
              <Button
                variant="outlined"
                onClick={toggleEditMode}
              >
                Cancelar
              </Button>
              <Button
                variant="contained"
                onClick={handleSave}
                disabled={!canEditGeneral && !canEditDetails}
              >
                Guardar
              </Button>
            </>
          )}
        </DialogActions>
      </Dialog>

      <ConfirmDialog
        open={confirmDialogOpen}
        title={confirmDialogAction === 'save' ? "Confirmar Guardar" : "Confirmar Cerrar"}
        content={
          confirmDialogAction === 'save'
            ? "¿Está seguro de que desea guardar los cambios?"
            : "Hay cambios sin guardar. ¿Está seguro de que desea cerrar sin guardar?"
        }
        onConfirm={confirmDialogAction === 'save' ? handleConfirmSave : handleConfirmClose}
        onCancel={() => setConfirmDialogOpen(false)}
      />
    </>
  );
};

export default PlaceholderDetailsModal;