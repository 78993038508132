// src/core/utils/api.js

import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL || 'http://localhost:5000/api';

export const uploadImage = async (file) => {
  try {
    const formData = new FormData();
    formData.append('image', file);

    const response = await axios.post(`${API_URL}/upload-image`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    });

    return response.data.imageUrl;
  } catch (error) {
    console.error('Error uploading image:', error);
    throw error;
  }
};

export const checkAdminSession = async () => {
  try {
    const token = localStorage.getItem('adminToken');
    if (!token) {
      throw new Error('No token found');
    }
    const response = await axios.get(`${API_URL}/admin/check-session`, {
      headers: {
        Authorization: token,
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error checking admin session:', error);
    throw error;
  }
};

export const clientRegister = async (userData) => {
  try {
    const response = await axios.post(`${API_URL}/clientRegister`, userData);
    return response.data;
  } catch (error) {
    console.error('Error registering client:', error);
    throw error;
  }
};

export const adminRegister = async (adminData) => {
  try {
    const response = await axios.post(`${API_URL}/admin/register`, adminData);
    return response.data;
  } catch (error) {
    console.error('Error registering admin:', error);
    throw error;
  }
};

export const adminLogin = async (credentials) => {
  try {
    const response = await axios.post(`${API_URL}/admin/login`, credentials);
    return response.data;
  } catch (error) {
    console.error('Error logging in admin:', error);
    throw error;
  }
};

export const adminLogout = async () => {
  try {
    const response = await axios.post(`${API_URL}/admin/logout`);
    return response.data;
  } catch (error) {
    console.error('Error logging out admin:', error);
    throw error;
  }
};

export const getAdminUsers = async () => {
  try {
    const response = await axios.get(`${API_URL}/admin/users`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching admin users:', error);
    throw error;
  }
};

export const updateAdminUser = async (uid, userData) => {
  try {
    const response = await axios.put(`${API_URL}/admin/users/${uid}`, userData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating admin user:', error);
    throw error;
  }
};

export const deleteAdminUser = async (uid) => {
  try {
    const response = await axios.delete(`${API_URL}/admin/users/${uid}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error deleting admin user:', error);
    if (error.response && error.response.status === 404) {
      throw new Error('Usuario no encontrado');
    } else {
      throw new Error('Error al eliminar usuario administrador');
    }
  }
};

export const disableAdminUser = async (uid) => {
  try {
    const response = await axios.put(`${API_URL}/admin/users/${uid}/disable`, {}, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error disabling admin user:', error);
    throw error;
  }
};

export const getRolePermissions = async () => {
  try {
    const response = await axios.get(`${API_URL}/role-permissions`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching role permissions:', error);
    throw error;
  }
};

export const updateRolePermissions = async (permissions) => {
  try {
    const response = await axios.put(`${API_URL}/role-permissions`, permissions, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error updating role permissions:', error);
    throw error;
  }
};

export const addOrderHistoryEvents = async (orderId, historyEvents) => {
  try {
    const response = await axios.post(`${API_URL}/order/${orderId}/history`, historyEvents);
    return response.data;
  } catch (error) {
    console.error('Error adding order history events:', error);
    throw error;
  }
};

export const updateFormData = async (id, formData) => {
  try {
    const response = await axios.put(`${API_URL}/form-data/${id}`, formData);
    return response.data;
  } catch (error) {
    console.error('Error updating form data:', error);
    throw error;
  }
};

export const checkIdExists = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/check-id/${id}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data.exists;
  } catch (error) {
    console.error('Error checking ID:', error);
    throw error;
  }
};

export const checkRNCExists = async (rnc) => {
  try {
    const response = await axios.get(`${API_URL}/check-rnc/${rnc}`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data.exists;
  } catch (error) {
    console.error('Error checking RNC:', error);
    throw error;
  }
};

export const getApplicationStatus = async (id) => {
  try {
    const response = await axios.get(`${API_URL}/application-status/${id}`);
    return response.data;
  } catch (error) {
    console.error('Error fetching application status:', error);
    throw error;
  }
};

export const addFormData = async (formData) => {
  try {
    const response = await axios.post(`${API_URL}/form-data`, formData, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error adding form data:', error);
    throw error;
  }
};

export const getFormData = async () => {
  try {
    const response = await axios.get(`${API_URL}/form-data`, {
      headers: {
        Authorization: localStorage.getItem('adminToken'),
      },
    });
    return response.data;
  } catch (error) {
    console.error('Error fetching form data:', error);
    throw error;
  }
};