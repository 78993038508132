// src/core/config/routes.js

import HomePage from '../../modules/dashboard/pages/HomePage';
import LoginPage from '../pages/LoginPage/LoginPage';
import UserPage from '../pages/UserPage/UserPage';
import PlaceholderPage from '../../modules/placeholderTable/pages/PlaceholderPage';
import FormPage from '../../modules/form/pages/FormPage';
import PanelPage from '../pages/PanelPage/PanelPage';
import StatusPage from '../../modules/status/pages/StatusPage';

export const routes = [
  { path: '/login', component: LoginPage, requiresAuth: false },
  { path: '/', component: HomePage, requiresAuth: true },
  { path: '/form', component: FormPage, requiresAuth: true },
  { path: '/admin/panel', component: PanelPage, requiresAuth: true },
  { path: '/users', component: UserPage, requiresAuth: true },
  { path: '/placeholder', component: PlaceholderPage, requiresAuth: true },
  { path: '/application/:id/status', component: StatusPage, requiresAuth: false },
];