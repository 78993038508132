import React, { useState } from 'react';
import { Box, Typography, TextField, Button, FormControl, InputLabel, Select, MenuItem, CircularProgress, Grid, IconButton, Alert } from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import { format } from 'date-fns';
import { v4 as uuidv4 } from 'uuid';
import { addFormData, checkRNCExists } from '../../../core/utils/api';

const FormComponent = ({ onSubmitSuccess, onSubmitError }) => {
  const [formData, setFormData] = useState({
    nombre: '',
    rncCedula: '',
    rncRelacionado: '',
    direccion: '',
    sector: '',
    provincia: '',
    municipio: '',
    telefono: '',
    movil: '',
    correo: '',
    fechaConstitucion: '',
    actividadComercial: '',
    formaPago: '',
    diaPago: '',
    inmuebleNegocio: '',
    seguroContraIncendio: '',
    companiaAseguradora: '',
    cargoContacto: '',
    administradorNombre: '',
    administradorTelefono: '',
    administradorCorreo: '',
    encComprasNombre: '',
    encComprasTelefono: '',
    encComprasCorreo: '',
    encCxPNombre: '',
    encCxPTelefono: '',
    encCxPCorreo: '',
    status: '',
    notas: [],
  });
  const [isLoading, setIsLoading] = useState(false);
  const [newNota, setNewNota] = useState('');
  const [rncError, setRncError] = useState('');

  const handleChange = async (e) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });

    if (name === 'rncCedula') {
      try {
        const exists = await checkRNCExists(value);
        if (exists) {
          setRncError('Este RNC ya está registrado en el sistema.');
        } else {
          setRncError('');
        }
      } catch (error) {
        console.error('Error checking RNC:', error);
      }
    }
  };

  const handleAddNota = () => {
    if (newNota.trim()) {
      setFormData(prevData => ({
        ...prevData,
        notas: [...prevData.notas, newNota.trim()]
      }));
      setNewNota('');
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (rncError) {
      alert('Por favor, corrija los errores antes de enviar el formulario.');
      return;
    }
    setIsLoading(true);
    try {
      const uuid = uuidv4();
      const displayId = uuid.slice(-6);
      const dataToSubmit = {
        ...formData,
        id: uuid,
        displayId,
        date: format(new Date(), 'dd/MM/yyyy'),
      };
      await addFormData(dataToSubmit);
      onSubmitSuccess(displayId);
      setFormData({
        nombre: '',
        rncCedula: '',
        rncRelacionado: '',
        direccion: '',
        sector: '',
        provincia: '',
        municipio: '',
        telefono: '',
        movil: '',
        correo: '',
        fechaConstitucion: '',
        actividadComercial: '',
        formaPago: '',
        diaPago: '',
        inmuebleNegocio: '',
        seguroContraIncendio: '',
        companiaAseguradora: '',
        cargoContacto: '',
        administradorNombre: '',
        administradorTelefono: '',
        administradorCorreo: '',
        encComprasNombre: '',
        encComprasTelefono: '',
        encComprasCorreo: '',
        encCxPNombre: '',
        encCxPTelefono: '',
        encCxPCorreo: '',
        status: '',
        notas: [],
      });
      setRncError('');
    } catch (error) {
      console.error('Error submitting form:', error);
      onSubmitError();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Box sx={{ width: '100%', maxWidth: '800px' }}>
      <Typography variant="h4" gutterBottom>
        Formulario de Solicitud de Crédito
      </Typography>
      <form onSubmit={handleSubmit}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nombre"
              name="nombre"
              value={formData.nombre}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="RNC / Cédula"
              name="rncCedula"
              value={formData.rncCedula}
              onChange={handleChange}
              required
              error={!!rncError}
              helperText={rncError}
            />
          </Grid>
          <Grid item xs={12} md={6}>
            <TextField
              fullWidth
              label="RNC Relacionado"
              name="rncRelacionado"
              value={formData.rncRelacionado}
              onChange={handleChange}
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Dirección"
              name="direccion"
              value={formData.direccion}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Sector"
              name="sector"
              value={formData.sector}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Provincia"
              name="provincia"
              value={formData.provincia}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Municipio"
              name="municipio"
              value={formData.municipio}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Teléfono"
              name="telefono"
              value={formData.telefono}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <TextField
              fullWidth
              label="Móvil"
              name="movil"
              value={formData.movil}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Correo"
              name="correo"
              type="email"
              value={formData.correo}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Fecha Constitución del Negocio"
              name="fechaConstitucion"
              type="date"
              value={formData.fechaConstitucion}
              onChange={handleChange}
              InputLabelProps={{ shrink: true }}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Actividad Comercial del Negocio / Solicitante"
              name="actividadComercial"
              value={formData.actividadComercial}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel>Forma de Pago</InputLabel>
              <Select
                name="formaPago"
                value={formData.formaPago}
                onChange={handleChange}
              >
                <MenuItem value="Cheque">Cheque</MenuItem>
                <MenuItem value="Efectivo">Efectivo</MenuItem>
                <MenuItem value="Transferencia">Transferencia</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel>Día(s) de Pago</InputLabel>
              <Select
                name="diaPago"
                value={formData.diaPago}
                onChange={handleChange}
              >
                {[...Array(31)].map((_, i) => (
                  <MenuItem key={i + 1} value={i + 1}>{i + 1}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel>Inmueble del Negocio</InputLabel>
              <Select
                name="inmuebleNegocio"
                value={formData.inmuebleNegocio}
                onChange={handleChange}
              >
                <MenuItem value="Propio">Propio</MenuItem>
                <MenuItem value="Alquilado">Alquilado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item xs={6}>
            <FormControl fullWidth required>
              <InputLabel>Seguro Contra Incendio</InputLabel>
              <Select
                name="seguroContraIncendio"
                value={formData.seguroContraIncendio}
                onChange={handleChange}
              >
                <MenuItem value="Si">Sí</MenuItem>
                <MenuItem value="No">No</MenuItem>
              </Select>
            </FormControl>
          </Grid>
          {formData.seguroContraIncendio === 'Si' && (
            <Grid item xs={12}>
              <TextField
                fullWidth
                label="Compañía Aseguradora"
                name="companiaAseguradora"
                value={formData.companiaAseguradora}
                onChange={handleChange}
                required
              />
            </Grid>
          )}
          <Grid item xs={12}>
            <Typography variant="h6" gutterBottom>
              Información de Contacto
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Cargo"
              name="cargoContacto"
              value={formData.cargoContacto}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Administrador</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Nombre y Apellido"
              name="administradorNombre"
              value={formData.administradorNombre}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Tel. Móvil"
              name="administradorTelefono"
              value={formData.administradorTelefono}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Correo"
              name="administradorCorreo"
              type="email"
              value={formData.administradorCorreo}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Enc. de Compras</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Nombre y Apellido"
              name="encComprasNombre"
              value={formData.encComprasNombre}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Tel. Móvil"
              name="encComprasTelefono"
              value={formData.encComprasTelefono}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Correo"
              name="encComprasCorreo"
              type="email"
              value={formData.encComprasCorreo}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="subtitle1">Enc. de CxP</Typography>
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Nombre y Apellido"
              name="encCxPNombre"
              value={formData.encCxPNombre}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Tel. Móvil"
              name="encCxPTelefono"
              value={formData.encCxPTelefono}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={4}>
            <TextField
              fullWidth
              label="Correo"
              name="encCxPCorreo"
              type="email"
              value={formData.encCxPCorreo}
              onChange={handleChange}
              required
            />
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6">Notas</Typography>
          </Grid>
          {formData.notas.map((nota, index) => (
            <Grid item xs={12} key={index}>
              <TextField
                fullWidth
                label={`Nota ${index + 1}`}
                value={nota}
                disabled
              />
            </Grid>
          ))}
          <Grid item xs={12}>
            <TextField
              fullWidth
              label="Nueva Nota"
              value={newNota}
              onChange={(e) => setNewNota(e.target.value)}
            />
          </Grid>
          <Grid item xs={12}>
            <Button
              startIcon={<AddIcon />}
              onClick={handleAddNota}
              disabled={!newNota.trim()}
            >
              Agregar Nueva Nota
            </Button>
          </Grid>

          {/* Estado dropdown */}
          <Grid item xs={12}>
            <FormControl fullWidth required>
              <InputLabel>Estado</InputLabel>
              <Select
                name="status"
                value={formData.status}
                onChange={handleChange}
              >
                <MenuItem value="Pendiente">Pendiente</MenuItem>
                <MenuItem value="Recibido">Recibido</MenuItem>
                <MenuItem value="Procesando">Procesando</MenuItem>
                <MenuItem value="Aprobado">Aprobado</MenuItem>
                <MenuItem value="Rechazado">Rechazado</MenuItem>
              </Select>
            </FormControl>
          </Grid>
        </Grid>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          sx={{ mt: 3, mb: 3 }}
          disabled={isLoading || !!rncError}
        >
          {isLoading ? <CircularProgress size={24} /> : 'Enviar'}
        </Button>
      </form>
    </Box>
  );
};

export default FormComponent;