// src/core/context/AdminAuthContext.js

import React, { createContext, useState, useContext, useCallback, useMemo, useEffect } from 'react';
import { adminLogin, adminLogout, getRolePermissions } from '../utils/api';

const AdminAuthContext = createContext();

export const useAdminAuth = () => {
  const context = useContext(AdminAuthContext);
  if (!context) {
    throw new Error('useAdminAuth must be used within an AdminAuthProvider');
  }
  return context;
};

export const AdminAuthProvider = ({ children }) => {
  const [adminUser, setAdminUser] = useState(() => {
    const storedUser = localStorage.getItem('adminUser');
    if (storedUser) {
      try {
        return JSON.parse(storedUser);
      } catch (error) {
        console.error('Error parsing adminUser from localStorage:', error);
        localStorage.removeItem('adminUser');
      }
    }
    return null;
  });

  const [permissions, setPermissions] = useState(() => {
    const storedPermissions = localStorage.getItem('adminPermissions');
    if (storedPermissions) {
      try {
        return JSON.parse(storedPermissions);
      } catch (error) {
        console.error('Error parsing adminPermissions from localStorage:', error);
        localStorage.removeItem('adminPermissions');
      }
    }
    return null;
  });

  const [loading, setLoading] = useState(false);
  const [sessionStartTime, setSessionStartTime] = useState(null);

  useEffect(() => {
    if (adminUser && !sessionStartTime) {
      setSessionStartTime(Date.now());
    }
  }, [adminUser, sessionStartTime]);

  const login = useCallback(async (email, password) => {
    setLoading(true);
    try {
      const userData = await adminLogin({ email, password });
      setAdminUser(userData);
      localStorage.setItem('adminUser', JSON.stringify(userData));

      // Fetch and store permissions
      const userPermissions = await getRolePermissions();
      setPermissions(userPermissions[userData.role]);
      localStorage.setItem('adminPermissions', JSON.stringify(userPermissions[userData.role]));

      setSessionStartTime(Date.now());

      return userData;
    } catch (error) {
      console.error('AdminAuthContext: Login error:', error);
      throw error;
    } finally {
      setLoading(false);
    }
  }, []);

  const logout = useCallback(async () => {
    setLoading(true);
    try {
      await adminLogout();
      setAdminUser(null);
      setPermissions(null);
      setSessionStartTime(null);
      localStorage.removeItem('adminUser');
      localStorage.removeItem('adminPermissions');
    } catch (error) {
      console.error('AdminAuthContext: Error during logout:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  const checkPermission = useCallback((functionalityId) => {
    return permissions ? permissions[functionalityId] : false;
  }, [permissions]);

  const resetSession = useCallback(() => {
    setSessionStartTime(Date.now());
  }, []);

  const value = useMemo(() => ({
    adminUser,
    permissions,
    loading,
    login,
    logout,
    checkPermission,
    sessionStartTime,
    resetSession,
  }), [adminUser, permissions, loading, login, logout, checkPermission, sessionStartTime, resetSession]);

  return (
    <AdminAuthContext.Provider value={value}>
      {children}
    </AdminAuthContext.Provider>
  );
};