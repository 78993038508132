// src/modules/placeholderTable/components/PrintableEntry.js

import React, { useMemo } from 'react';
import { Typography, Box, Grid, Paper, Divider } from '@mui/material';
import { styled } from '@mui/material/styles';
import { QRCodeSVG } from 'qrcode.react';

const PrintContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: 'white',
  color: 'black',
  fontSize: '10pt',
}));

const Header = styled(Box)(({ theme }) => ({
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(2),
}));

const LogoContainer = styled(Box)({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'flex-start',
});

const CompanyInfo = styled(Typography)({
  fontSize: '8pt',
  marginTop: '4px',
});

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  fontWeight: 'bold',
  marginBottom: theme.spacing(0.5),
  color: theme.palette.primary.main,
  fontSize: '11pt',
}));

const Field = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(0.5),
  fontSize: '10pt',
}));

const MemoizedQRCode = React.memo(({ url }) => (
    <QRCodeSVG value={url} size={64} />
  ));
  
  const PrintableEntry = React.forwardRef(({ entry }, ref) => {
    const qrCodeUrl = useMemo(() => 
        entry ? `${window.location.origin}/application/${entry.id}/status` : '',
        [entry?.id]
      );
  
    if (!entry) return null;
  
    return (
      <PrintContainer ref={ref}>
        <Paper elevation={0} sx={{ p: 2 }}>
          <Header>
            <LogoContainer>
              <img src="/img/logo.png" alt="Logo" style={{ height: '50px' }} />
              <CompanyInfo>Tel. 809-472-6911 / RNC: 101-01383-4</CompanyInfo>
            </LogoContainer>
            <MemoizedQRCode url={qrCodeUrl} />
          </Header>
          
          <Typography variant="h5" gutterBottom align="center" sx={{ fontSize: '14pt' }}>Solicitud de Crédito</Typography>
          <Divider sx={{ mb: 1 }} />
          
          <Grid container spacing={1}>
            <Grid item xs={6}>
              <Field><strong>ID:</strong> {entry.displayId}</Field>
            </Grid>
            <Grid item xs={6}>
              <Field><strong>Fecha:</strong> {entry.date}</Field>
            </Grid>
          </Grid>

        <Section>
          <SectionTitle variant="h6">Información General</SectionTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}><Field><strong>Nombre:</strong> {entry.nombre || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={6}><Field><strong>RNC / Cédula:</strong> {entry.rncCedula || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={6}><Field><strong>RNC Relacionado:</strong> {entry.rncRelacionado || 'N/A'}</Field></Grid>
            <Grid item xs={12}><Field><strong>Dirección:</strong> {entry.direccion || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Sector:</strong> {entry.sector || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Provincia:</strong> {entry.provincia || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Municipio:</strong> {entry.municipio || 'N/A'}</Field></Grid>
          </Grid>
        </Section>

        <Section>
          <SectionTitle variant="h6">Información de Contacto</SectionTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={4}><Field><strong>Teléfono:</strong> {entry.telefono || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Móvil:</strong> {entry.movil || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Correo:</strong> {entry.correo || 'N/A'}</Field></Grid>
          </Grid>
        </Section>

        <Section>
          <SectionTitle variant="h6">Información del Negocio</SectionTitle>
          <Grid container spacing={1}>
            <Grid item xs={12} sm={6}><Field><strong>Fecha Constitución:</strong> {entry.fechaConstitucion || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={6}><Field><strong>Actividad Comercial:</strong> {entry.actividadComercial || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Forma de Pago:</strong> {entry.formaPago || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Día de Pago:</strong> {entry.diaPago || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}><Field><strong>Inmueble del Negocio:</strong> {entry.inmuebleNegocio || 'N/A'}</Field></Grid>
          </Grid>
        </Section>

        <Section>
          <SectionTitle variant="h6">Información de Contacto Adicional</SectionTitle>
          <Grid container spacing={1}>
            <Grid item xs={12}><Field><strong>Cargo:</strong> {entry.cargoContacto || 'N/A'}</Field></Grid>
            <Grid item xs={12} sm={4}>
              <Field><strong>Administrador:</strong></Field>
              <Field>Nombre: {entry.administradorNombre || 'N/A'}</Field>
              <Field>Teléfono: {entry.administradorTelefono || 'N/A'}</Field>
              <Field>Correo: {entry.administradorCorreo || 'N/A'}</Field>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field><strong>Enc. de Compras:</strong></Field>
              <Field>Nombre: {entry.encComprasNombre || 'N/A'}</Field>
              <Field>Teléfono: {entry.encComprasTelefono || 'N/A'}</Field>
              <Field>Correo: {entry.encComprasCorreo || 'N/A'}</Field>
            </Grid>
            <Grid item xs={12} sm={4}>
              <Field><strong>Enc. de CxP:</strong></Field>
              <Field>Nombre: {entry.encCxPNombre || 'N/A'}</Field>
              <Field>Teléfono: {entry.encCxPTelefono || 'N/A'}</Field>
              <Field>Correo: {entry.encCxPCorreo || 'N/A'}</Field>
            </Grid>
          </Grid>
        </Section>

        <Section>
          <SectionTitle variant="h6">Notas</SectionTitle>
          {entry.notas && entry.notas.length > 0 ? (
            entry.notas.map((nota, index) => (
              <Field key={index}>{nota}</Field>
            ))
          ) : (
            <Field>No hay notas disponibles</Field>
          )}
        </Section>

        <Section>
          <SectionTitle variant="h6">Estado</SectionTitle>
          <Field>{entry.status || 'N/A'}</Field>
        </Section>
      </Paper>
    </PrintContainer>
  );
});

export default PrintableEntry;