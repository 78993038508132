// src/modules/dashboard/components/DashboardComponent.js

import React, { useState, useEffect } from 'react';
import { Box, Typography, Grid, Paper, CircularProgress } from '@mui/material';
import { BarChart, Bar, LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { getFormData } from '../../../core/utils/api';

const DashboardComponent = () => {
  const [formData, setFormData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetchFormData();
  }, []);

  const fetchFormData = async () => {
    try {
      const data = await getFormData();
      setFormData(data);
      setLoading(false);
    } catch (error) {
      console.error('Error fetching form data:', error);
      setLoading(false);
    }
  };

  const getStatusCounts = () => {
    const allStatuses = ['Pendiente', 'Recibido', 'Procesando', 'Aprobado', 'Rechazado'];
    const counts = formData.reduce((acc, item) => {
      acc[item.status] = (acc[item.status] || 0) + 1;
      return acc;
    }, {});
    return allStatuses.map(status => ({ status, count: counts[status] || 0 }));
  };

  const getTotalEntries = () => formData.length;

  const getLatestEntry = () => {
    if (formData.length === 0) return null;
    return formData.reduce((latest, current) => 
      new Date(current.date) > new Date(latest.date) ? current : latest
    );
  };

  const getDataByDate = () => {
    const counts = formData.reduce((acc, item) => {
      acc[item.date] = (acc[item.date] || 0) + 1;
      return acc;
    }, {});
    return Object.entries(counts)
      .map(([date, count]) => ({ date, count }))
      .sort((a, b) => new Date(a.date) - new Date(b.date));
  };

  if (loading) {
    return (
      <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '400px' }}>
        <CircularProgress />
      </Box>
    );
  }

  const statusCounts = getStatusCounts();

  return (
    <Box sx={{ flexGrow: 1, mt: 4 }}>
      <Grid container spacing={3}>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
            <Typography variant="h6" gutterBottom>
              Total de Entradas
            </Typography>
            <Typography variant="h3" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {getTotalEntries()}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
            <Typography variant="h6" gutterBottom>
              Última Entrada
            </Typography>
            <Typography variant="body1" component="div" sx={{ flexGrow: 1, display: 'flex', flexDirection: 'column', justifyContent: 'center' }}>
              {getLatestEntry() ? (
                <>
                  <span>ID: {getLatestEntry().displayId}</span>
                  <span>Fecha: {getLatestEntry().date}</span>
                </>
              ) : (
                'No hay entradas'
              )}
            </Typography>
          </Paper>
        </Grid>
        <Grid item xs={12} md={4}>
          <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', height: 140 }}>
            <Typography variant="h6" gutterBottom>
              Estado más común
            </Typography>
            <Typography variant="h5" component="div" sx={{ flexGrow: 1, display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
              {statusCounts.reduce((a, b) => a.count > b.count ? a : b).status}
            </Typography>
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Grid container spacing={2}>
            {statusCounts.map((status) => (
              <Grid item xs={12} sm={6} md={2.4} key={status.status}>
                <Paper sx={{ p: 2, display: 'flex', flexDirection: 'column', alignItems: 'center', height: 100 }}>
                  <Typography variant="h6" gutterBottom>
                    {status.status}
                  </Typography>
                  <Typography variant="h4" component="div">
                    {status.count}
                  </Typography>
                </Paper>
              </Grid>
            ))}
          </Grid>
        </Grid>

        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 300, pb: 6 }}>
            <Typography variant="h6" gutterBottom>
              Entradas por Estado
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <BarChart data={statusCounts}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="status" dy={5} />
                <YAxis allowDecimals={false} />
                <Tooltip />
                {/* <Legend /> */}
                <Bar dataKey="count" fill="#b60d13" />
              </BarChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
        <Grid item xs={12} md={6}>
          <Paper sx={{ p: 2, height: 300, pb: 6 }}>
            <Typography variant="h6" gutterBottom>
              Entradas por Fecha
            </Typography>
            <ResponsiveContainer width="100%" height="100%">
              <LineChart data={getDataByDate()}>
                <CartesianGrid strokeDasharray="3 3" />
                <XAxis dataKey="date" dy={5} />
                <YAxis allowDecimals={false} />
                <Tooltip />
                {/* <Legend /> */}
                <Line type="monotone" dataKey="count" stroke="#b60d13" strokeWidth={2} />
              </LineChart>
            </ResponsiveContainer>
          </Paper>
        </Grid>
      </Grid>
    </Box>
  );
};

export default DashboardComponent;