// src/App.js
import React from 'react';
import { BrowserRouter, Routes, Route, Navigate } from 'react-router-dom';
import { ThemeProvider } from '@mui/material/styles';
import theme from './core/theme/theme';
import { AdminAuthProvider, useAdminAuth } from './core/context/AdminAuthContext';
import SessionTimeoutModal from './core/components/SessionTimeoutModal/SessionTimeoutModal';
import { routes } from './core/config/routes';

const ProtectedRoute = ({ children, requiresAuth }) => {
  const { adminUser, loading } = useAdminAuth();

  if (loading) {
    return <div>Cargando...</div>;
  }

  if (requiresAuth && !adminUser) {
    return <Navigate to="/login" />;
  }

  return children;
};

function AppRoutes() {
  const { adminUser } = useAdminAuth();

  return (
    <>
      <Routes>
        {routes.map((route) => (
          <Route
            key={route.path}
            path={route.path}
            element={
              <ProtectedRoute requiresAuth={route.requiresAuth}>
                {route.requiresAuth && adminUser ? (
                  <route.component />
                ) : route.requiresAuth ? (
                  <Navigate to="/login" />
                ) : (
                  <route.component />
                )}
              </ProtectedRoute>
            }
          />
        ))}
      </Routes>
      {adminUser && <SessionTimeoutModal />}
    </>
  );
}

function App() {
  return (
    <ThemeProvider theme={theme}>
      <AdminAuthProvider>
        <BrowserRouter>
          <AppRoutes />
        </BrowserRouter>
      </AdminAuthProvider>
    </ThemeProvider>
  );
}

export default App;