import React, { useRef, useState } from 'react';
import { useReactToPrint } from 'react-to-print';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  Chip,
  IconButton,
  Tooltip,
  Box,
} from '@mui/material';
import PrintIcon from '@mui/icons-material/Print';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import PrintableEntry from './PrintableEntry';

const PlaceholderTable = ({ data, onOpenDetails, sortOrder, onSortOrderChange }) => {
  const [printableItem, setPrintableItem] = useState(null);
  const printableRef = useRef();

  const handlePrint = useReactToPrint({
    content: () => printableRef.current,
    onAfterPrint: () => setPrintableItem(null),
  });

  const onPrint = (item) => {
    setPrintableItem(item);
    setTimeout(handlePrint, 100);
  };

  const getStatusStyle = (status) => {
    switch (status) {
      case 'Aprobado':
        return { backgroundColor: 'green', color: 'white' };
      case 'Rechazado':
        return { backgroundColor: '#757575', color: 'white' };
      case 'Pendiente':
        return { backgroundColor: 'orange', color: 'white' };
      case 'Recibido':
        return { backgroundColor: 'blue', color: 'white' };
      case 'Procesando':
        return { backgroundColor: 'purple', color: 'white' };
      default:
        return {};
    }
  };

  return (
    <>
      <TableContainer component={Paper}>
        <Table sx={{ minWidth: 650 }} aria-label="tabla de solicitudes de crédito">
          <TableHead>
            <TableRow>
              <TableCell>ID</TableCell>
              <TableCell>Nombre</TableCell>
              <TableCell>RNC / Cédula</TableCell>
              <TableCell>RNC Relacionado</TableCell>
              <TableCell>Teléfono</TableCell>
              <TableCell>
                <Box sx={{ display: 'flex', alignItems: 'center' }}>
                  Fecha
                  <IconButton onClick={onSortOrderChange} size="small">
                    {sortOrder === 'desc' ? <ArrowDownwardIcon /> : <ArrowUpwardIcon />}
                  </IconButton>
                </Box>
              </TableCell>
              <TableCell>Estado</TableCell>
              <TableCell align="center">Acciones</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data.map((item) => (
              <TableRow key={item.id}>
                <TableCell component="th" scope="row">
                  {item.displayId}
                </TableCell>
                <TableCell>{item.nombre}</TableCell>
                <TableCell>{item.rncCedula}</TableCell>
                <TableCell>{item.rncRelacionado}</TableCell>
                <TableCell>{item.telefono}</TableCell>
                <TableCell>{item.date}</TableCell>
                <TableCell>
                  <Chip
                    label={item.status}
                    sx={getStatusStyle(item.status)}
                  />
                </TableCell>
                <TableCell align="center">
                  <Button 
                    variant="contained" 
                    sx={{ 
                      backgroundColor: '#d60f16',
                      '&:hover': {
                        backgroundColor: '#b60d13',
                      },
                      mr: 1
                    }} 
                    onClick={() => onOpenDetails(item)}
                  >
                    Detalles
                  </Button>
                  <Tooltip title="Imprimir">
                    <IconButton 
                      onClick={() => onPrint(item)}
                      color="default"
                    >
                      <PrintIcon />
                    </IconButton>
                  </Tooltip>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>

      <div style={{ display: 'none' }}>
        {printableItem && <PrintableEntry ref={printableRef} entry={printableItem} />}
      </div>
    </>
  );
};

export default PlaceholderTable;