// src/core/components/AccessDenied/AccessDenied.js

import React from 'react';
import { Box, Typography } from '@mui/material';
import Header from '../Header/Header';
import SideMenu from '../SideMenu/SideMenu';

const AccessDenied = () => {
  return (
    <Box sx={{ display: 'flex' }}>
      <SideMenu />
      <Box sx={{ flexGrow: 1, height: '100vh', overflow: 'hidden', display: 'flex', flexDirection: 'column' }}>
        <Header />
        <Box sx={{ 
          flexGrow: 1, 
          display: 'flex', 
          flexDirection: 'column', 
          justifyContent: 'center', 
          alignItems: 'center',
          p: 3 
        }}>
          <Typography variant="h4" gutterBottom>
            Acceso Denegado
          </Typography>
          <Typography>
            No tienes permiso para ver esta página.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

export default AccessDenied;