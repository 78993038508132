// src/modules/status/pages/StatusPage.js

import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { Typography, Box, Paper, Chip, CircularProgress, Link } from '@mui/material';
import { styled } from '@mui/material/styles';
import { getApplicationStatus } from '../../../core/utils/api';

const PageContainer = styled(Box)(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  alignItems: 'center',
  minHeight: '100vh',
  padding: theme.spacing(2),
  backgroundColor: '#f5f5f5',
}));

const Logo = styled('img')({
  width: '200px',
  marginBottom: '20px',
});

const ContactInfo = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: 'flex',
  alignItems: 'center',
  gap: theme.spacing(2),
}));

const StatusPage = () => {
  const { id } = useParams();
  const [status, setStatus] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchStatus = async () => {
      try {
        const data = await getApplicationStatus(id);
        setStatus(data);
      } catch (err) {
        setError('Error al cargar el estado de la solicitud');
      } finally {
        setLoading(false);
      }
    };

    fetchStatus();
  }, [id]);

  if (loading) {
    return (
      <PageContainer>
        <CircularProgress />
      </PageContainer>
    );
  }

  if (error) {
    return (
      <PageContainer>
        <Typography color="error">{error}</Typography>
      </PageContainer>
    );
  }

  return (
    <PageContainer>
      <Logo src="/img/logo.png" alt="Logo" />
      <Paper elevation={3} sx={{ p: 4, maxWidth: 400, width: '100%' }}>
        <Typography variant="h4" gutterBottom>Estado de la Solicitud</Typography>
        <Typography variant="subtitle1" gutterBottom>ID: {status.displayId}</Typography>
        <Typography variant="body1" gutterBottom>Nombre: {status.nombre}</Typography>
        <Typography variant="body1" gutterBottom>Fecha: {status.date}</Typography>
        <Box mt={2}>
          <Chip 
            label={status.status} 
            color={
              status.status === 'Aprobado' ? 'success' :
              status.status === 'Rechazado' ? 'error' :
              status.status === 'Pendiente' ? 'warning' :
              status.status === 'Procesando' ? 'info' : 'default'
            }
          />
        </Box>
      </Paper>
      <ContactInfo>
        <Typography variant="body2">Tel. 809-472-6911</Typography>
        <Link 
          href="https://wa.me/18096076911" 
          target="_blank" 
          rel="noopener noreferrer"
          variant="body2"
          color="primary"
        >
          WhatsApp
        </Link>
      </ContactInfo>
    </PageContainer>
  );
};

export default StatusPage;